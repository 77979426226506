@import '../../App.scss';

.mobile_nav_menu {
	background-color: rgba(0, 0, 0, 0.822);
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 98;

	top: 0;
	left: 0;
}

.mobile_nav_links {
	list-style: none;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	width: 100%;
	height: 100%;

	li {
		color: $font-color;
		font-family: "Neutral Face";
		font-weight: 300;
		cursor: pointer;

		font-size: 5.5vh;
		margin: .5em;
	}
}