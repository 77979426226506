.marker {
	position: relative;
	top: 50%;
	left: 50%;
	width: 12.5px;
	height: 12.5px;
	// background-color: #000;
	// border: 2px solid #fff;
	// border-radius: 100%;
	user-select: none;
	transform: translate(-50%, -50%);
	&:hover {
		z-index: 1;
	}

	img {
		position: absolute;
		top: -1.5em;
		// left: 1.5em;
		width: 3.5em;
	}
}
