@import '../../App.scss';

.contact {
	grid-row: 6;
	grid-column: col-start 3 / span 8;

	color: $font-color;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	font-family: "Nuetral Face";
	margin-bottom: 12.5em;

	h3 {
		@include title_styling;
	}

	a {
		text-decoration: none;
		margin-bottom: -5em;


		h2 {
			text-align: center;
			font-size: 17.5vw;
			margin: 1rem 0 0 0;
			color: $font-color;
	
			font-weight: 300;
			text-align: center;
		}

		.mobile_contact {
			font-size: 30vw;
			line-height: 1em;
		}
	}
	
	
}