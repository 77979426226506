@import '../../App.scss';

.landing_text {
	grid-row: 2;
	grid-column: col-start 4 / span 6;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	margin: 12.5em 0 12.5em 0;

	h2,h3 {
		color: $font-color;
		margin: 0;
		padding: 0;
	}

	h2 {
		font-family: 'Harmond';
		font-size: 15vw;
		margin-bottom: -.25em;
	}

	h3 {
		font-family: 'Neutral Face';
		font-size: 5vw;
		font-weight: 300;
		margin-top: .1em;
		white-space: nowrap;
	}
}

