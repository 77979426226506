@import '../../App.scss';

.footer {
	grid-column: col-start 1 / span 12;
	grid-row: 8;
	display: grid;
	gap: 20px;
	grid-template-columns: repeat(12, [col-start] 1fr);

	.shop_logo {
		grid-column: col-start 2;

		img {
			width: 3em;
			margin-top: 1em;
			cursor: pointer;
		}
	}

	.shop_information {
		grid-column: col-start 3;
		font-family: "Neutral Face";
		color: $font-color;

		font-size: .65em;
		white-space: nowrap;

		a {
			color: $font-color;
			text-decoration: none;
		}
	}

	.shop_days {
		grid-column: col-start 7;
		color: $font-color;
		font-family: "Neutral Face";
		font-size: .65em;
	}

	.shop_hours {
		grid-column: col-start 8;
		color: $font-color;
		font-family: "Neutral Face";
		font-size: .65em;
		white-space: nowrap;
	}

	.links {
		grid-column: col-start 10;
		color: $font-color;
		font-family: "Neutral Face";
		font-size: .65em;
		
		p {
			cursor: pointer;
		}
	}

	.madeby {
		grid-row: 2;
		grid-column: col-start 2 / span 10;
		width: 100%;
		color: $font-color;
		font-size: .65em;

		display: flex;
		justify-content: space-between;

		p {
			opacity: .5;
		}
	}

	@media screen and (max-width: 1000px) {
		.shop_logo {
			grid-column: col-start 3;
			grid-row: 1;
		}

		.shop_information {
			grid-column: col-start 3;
			grid-row: 2;
		}

		.shop_days {
			grid-column: col-start 3;
			grid-row: 3;
		}

		.shop_hours {
			grid-column: col-start 6;
			grid-row: 3;
		}

		.links {
			grid-column: col-start 3;
			grid-row: 4;
		}

		.madeby {
			grid-row: 5;
		}
	}
}

@media screen and (max-width: 1000px) {
	.footer {
		gap: 0;
	}
}

