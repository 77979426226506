@import '../../App.scss';

.header {
	grid-column: col-start 2 / span 10;
	color: $font-color;
	position: sticky;
	top: 0;
	z-index: 99;
	width: 100%;
	margin-bottom: -1em;
}

.navbar {
	display: flex;
	justify-content: space-between;
	position: sticky;
	align-items: center;
	margin-top: 1.75em;
}

@media screen and (max-width: 1000px) {
	.navbar {
		position: fixed;
		width: 85%;
		top: 0;
	}
}

.left_nav {
	img {
		width: 4em;
		cursor: pointer;
	}

	@media screen and (max-width: 1000px) {
		img {
			width: 3em;
		}
	}
}

.right_nav {
	display: relative;

	.hamburger {
		width: 2em;
	}

	.exit {
		width: 1.5em;
	}

	ul {
		display: flex;
		list-style: none;

		li {
			margin-left: 3em;
			font-family: 'Neutral Face';
			font-size: .75em;
			cursor: pointer;
		}
	}

	@media screen and (max-width: 1000px) {
		ul {
			display: none;
		}
	}
}

.right_nav .menu-icon {
	cursor: pointer;
	display: inline-block;
	padding: 10px 10px;
	position: relative;
	user-select: none;
  }
  
  .right_nav .menu-icon .navicon {
	background: $font-color;
	display: block;
	height: 3px;
	position: relative;
	transition: background .2s ease-out;
	width: 25px;
  }
  
  .right_nav .menu-icon .navicon:before,
  .right_nav .menu-icon .navicon:after {
	background: $font-color;
	content: '';
	display: block;
	height: 100%;
	position: absolute;
	transition: all .2s ease-out;
	width: 100%;
  }
  
  .right_nav .menu-icon .navicon:before {
	top: 7px;
  }
  
  .right_nav .menu-icon .navicon:after {
	top: -7px;
  }
  
  /* menu btn */
  
  .right_nav .menu-btn {
	display: none;
  }
  
  .right_nav .menu-btn:checked ~ .menu {
	max-height: 240px;
  }
  
  .right_nav .menu-btn:checked ~ .menu-icon .navicon {
	background: transparent;
  }
  
  .right_nav .menu-btn:checked ~ .menu-icon .navicon:before {
	transform: rotate(-45deg);
  }
  
  .right_nav .menu-btn:checked ~ .menu-icon .navicon:after {
	transform: rotate(45deg);
  }
  
  .right_nav .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
  .right_nav .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
	top: 0;
  }