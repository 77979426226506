@import "../../App.scss";

.story {
	grid-row: 3;
	grid-column: col-start 3 / span 8;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-bottom: 12.5em;

	position: relative;

	h3 {
		@include title_styling;
		// margin: 0;
		z-index: 90;
	}

	p {
		font-family: "Neutral Face";
		color: $font-color;
		font-size: 3.5em;
		text-align: center;
		// margin: 1em 0 1em 0;
		z-index: 50;
		// text-align: justify;
	}

	@media screen and (max-width: 1200px) {
		p {
			font-size: 2em;
		}
	}

	@media screen and (max-width: 500px) {
		p {
			font-size: 1.5em;
		}
	}

	.image_container {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;

		.stars_shop {
			filter: grayscale(100%);
			border: 20px solid white;

			opacity: 0;
			position: absolute;

			width: 30em;
			height: 30em;

			z-index: 1;

			&.is-active {
				opacity: 0.75;
			}
		}

		@media screen and (max-width: 1000px) {
			.stars_shop {
				width: 20em;
				height: 20em;
			}
		}

		@media screen and (max-width: 500px) {
			.stars_shop {
				width: 15em;
				height: 15em;
			}
		}
	}
}
