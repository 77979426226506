@import "../../App.scss";

.translatebuttons {
	position: absolute;
	bottom: 0;
	right: 1em;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: fixed;
	z-index: 99;

	button {
		height: 60px;
		line-height: 60px;
		width: 60px;
		font-size: 1em;
		font-weight: bold;
		border-radius: 50%;
		background-color: rgba(0, 0, 0, 0.9);
		color: $font-color;
		text-align: center;
		cursor: pointer;
		border: none;
		font-family: "Neutral Face";
		margin-bottom: 1em;
	}

	@media screen and (max-width: 1000px) {
		button {
			height: 40px;
			line-height: 40px;
			width: 40px;
			font-size: 0.5em;
			margin-bottom: 3.5em;

			&:first-of-type {
				margin-right: 1em;
			}
		}
	}

	.active {
		background-color: $font-color;
		color: rgba(0, 0, 0, 0.9);
	}
}

@media screen and (max-width: 1000px) {
	.translatebuttons {
		flex-direction: row;
		left: 50%;
		transform: translateX(-50%);
		bottom: .5em;
	}
}
