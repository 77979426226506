@import "../../App.scss";

.stylists {
	color: $font-color;

	grid-row: 5;
	grid-column: col-start 3 / span 8;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	margin-bottom: 12.5em;

	h3 {
		@include title_styling;
	}

	.stylist_container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 5em;
		width: 75vw;
		flex-wrap: wrap;
		gap: 5%;

		&:hover {
			.single_stylist {
				opacity: 0.5;
			}
		}

		.single_stylist {
			position: relative;
			overflow: hidden;
			border: 1.25vw solid $font-color;
			cursor: pointer;
			width: 35vw;
			height: auto;

			img {
				max-width: 100%;
				min-height: 100%;
				object-fit: cover;
				object-position: 0 -50px;
				filter: saturate(0);
				margin-bottom: -6em;
			}

			p {
				font-family: "Maghfirea";
				font-size: 5vw;
				margin: 0;
				position: absolute;
				bottom: 0.25em;
				left: 50%;
				transform: translateX(-50%);
				text-align: center;
			}

			@media screen and (max-width: 1000px) {
				p {
					font-size: 10vw;
				}
			}

			transition: transform 0.3s;
		}

		.single_stylist:hover {
			transform: scale(1.1);
			opacity: 1;
		}

		@media screen and (max-width: 1000px) {
			.single_stylist {
				border: 2vw solid white;
				width: 100%;
				height: 35vh;
			}
		}
	}

	@media screen and (max-width: 1000px) {
		.stylist_container {
			// flex-direction: column;
			flex-wrap: wrap;
			width: 50vw;
			justify-content: center;
		}
	}
}
