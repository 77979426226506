@import '../../App.scss';

.prices {
	color: $font-color;

	grid-row: 4;
	grid-column: col-start 3 / span 8;

	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-bottom: 12.5em;
	
	h3 {
		@include title_styling;
		// margin: 0;
	}

	.prices_container {
		margin: 3em 0 1em 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		padding: 0;

		&:hover {
			.single_price {
				opacity: 0.5;
			}
		}

		.single_price {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			font-family: "Neutral Face";
			list-style-type: none;
			cursor: pointer;

			border-bottom: 1px solid $font-color;

			.price_label {
				font-size: 2em;
				font-weight: 300;
			}

			.price_cost {
				font-size: 1.5em;
				font-weight: 300;
			}

			transition: opacity 0.3s;
		}

		.single_price:hover {
			opacity: 1;
		}

		@media screen and (max-width: 1000px) {
			.single_price {
				flex-direction: column-reverse;

				.price_label {
					font-size: 2.5em;
					margin: 0 0 .5em 0;
					text-align: center;
				}

				.price_cost {
					font-size: 1.5em;
					margin: 1em 0 .5em 0;
				}
			}
		}
	}

	.fine_print {
		margin: 0;
		align-self: flex-end;
		font-size: .5em;

		font-family: "Neutral Face";
	}

	@media screen and (max-width: 1000px) {
		.fine_print {
			font-size: .25em;
		}
	}
}