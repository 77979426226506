@import "../../App.scss";

.hours {
	grid-row: 7;
	grid-column: col-start 3 / span 8;
	width: 100%;
	height: 100%;
	// height: 50em;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	position: relative;

	h3 {
		@include title_styling;
		text-align: center;
	}

	.map_container {
		height: 50vh;
		width: 100%;

		margin-top: 5em;
	}
}

@media screen and (max-width: 1000px) {
	.hours {
		grid-column: col-start 1 / span 12;
	}
}
