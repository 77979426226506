@font-face {
	font-family: "Neutral Face";
	src: url("./assets/Fonts/NeutralFace.otf");
}

@font-face {
	font-family: "Harmond";
	src: url("./assets/Fonts/Harmond-ExtraBold&Italic.otf");
}

@font-face {
	font-family: "Maghfirea";
	src: url("./assets/Fonts/Maghfirea.otf");
}

$font-color: #f5f5f5;

@mixin title_styling {
	font-family: "Neutral Face";
	color: $font-color;
	font-weight: 300;
	font-size: 1em;
}

html {
	scroll-behavior: smooth;
	width: 100%;
	height: 100%;
}

body {
	background-color: rgba(0, 0, 0, 0.9);
	overflow-x: hidden;
	width: 100%;
	height: 100%;

	&::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}
	&::-webkit-scrollbar-track {
		background-color: rgba(0, 0, 0, 0.4);
		border-radius: 10px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #dfdfdf;
		border-radius: 10px;
	}
}

.App {
	display: grid;
	grid-template-columns: repeat(12, [col-start] 1fr);
	gap: 20px;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
}

* {
	box-sizing: border-box;
}

:target:before {
	content: "";
	display: block;
	height: 150px;
	margin: -150px 0 0;
}
